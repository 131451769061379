import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Footer() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("http://localhost:8081/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

  return (
    <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
    <div className="container py-5">
        <div className="row g-2">
        <div className="col-md-6 col-lg-6 col-xl-4 mb-2 mb-md-0">
        <div className="footer-item d-flex flex-column">
            <h4 className="text-white mb-0 ">Contact Info</h4>
            <hr className='text-light'/>
            <Link className=' text-light'><i className="fa fa-map-marker-alt me-2"></i> Sheger city Melka Nono Sub city behind  Gebriel Church  </Link>
            <Link  className=' text-light'><i className="fas fa-envelope me-2"></i>dejenefikadu@gmail.com</Link>
            <Link  className=' text-light'><i className="fas fa-phone me-2"></i>0911351866</Link>
            <div className="d-flex align-items-center mt-3">
            <a className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-white bg-white"  href="mailto:dejenefikadu@gmail.com "><i class="bi bi-envelope text-primary"></i></a>
            <Link className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-primary bg-white" target="_blank" to={"https://www.facebook.com/dejene.fekadu "}><i class="fab fa-facebook-f fw-normal text-primary"></i></Link>
            <Link className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-primary bg-white" target="_blank" to={"https://wa.me/+251911351866 "}><i class="fab fa-whatsapp  fw-normal text-success"></i></Link>
            <a className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 bg-white" href="tel:0911351866 " ><i class="fas fa-phone text-dark"></i></a>
          
            </div>
        </div>
    </div>
            
            <div className="col-md-6 col-lg-6 col-xl-4 mb-2 mb-md-0">
                <div className="footer-item d-flex flex-column">
                    <h4 className="text-white  mb-0">Explore</h4>
                    <hr className='text-light'/>
                    <Link  className=' text-light'><i className="fas fa-angle-right me-2"></i> Home</Link>

                    <Link to={'/about'} className=' text-light'><i className="fas fa-angle-right me-2"></i> About Us</Link>
                    <Link to={'/products'} className=' text-light'><i className="fas fa-angle-right me-2"></i>Products</Link>
                    <Link to={'/testimonials'} className=' text-light'><i className="fas fa-angle-right me-2"></i> Testimonials</Link>
                    <Link to={'/team'} className=' text-light'><i className="fas fa-angle-right me-2"></i> Our Team</Link>
                    <Link to={'/contact'} className=' text-light'><i className="fas fa-angle-right me-2"></i> Contact Us</Link>
                </div>
            </div>

            

           
            <div className="col-md-6 col-lg-6 col-xl-4 mb-2 mb-md-0">
                <div className="footer-item-post d-flex flex-column">
                    <h4 className="text-white  mb-0">Popular Post</h4>
                    <hr className='text-light'/>
                    {data.slice(0,2).map((blog,i)=>{
                        return            <div className="d-flex flex-column mb-3" key={i}>
                       
                        <Link  to={`/blogdetail/${blog.id}`} className=" text-light">{blog.subject}</Link>
                    </div>})}
                    
                    <div className="footer-btn text-start">
                        <Link to={'/blog'} className="btn btn-light rounded-pill px-4">View All Posts <i className="fa fa-arrow-right ms-1"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid copyright py-1">
    <div className="container">
        <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-start mb-md-0">
            <span className="ms-1">&copy; Copyright bright vision.</span>
            </div>
            <div className="col-md-6 text-center text-md-end ">
             
                Designed By <Link to={'https://fortinotech.com.et/'}  target="_blank" className="text-info " >Naol</Link> Distributed By <Link to={'https://fortinotech.com.et/'}  target="_blank"  className=" text-info " >Fortino Tech</Link>
            </div>
        </div>
    </div>
</div>


</div>

  )
}

export default Footer