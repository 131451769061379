import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Contact() {

    const [data, setdata] = useState({

        name:"",
        email:'',
        phone:'',
        subject:'',
        message:''
    });
    const [resp, setresp] = useState(null);
    
    const handlecontact=(e)=>{
        e.preventDefault()
       
        axios.post('http://localhost:8081/contact', {name:data.name,phone:data.phone,email:data.email,subject:data.subject,message:data.message})
          .then(res => {
            if (res.data.status==="success") {
            setresp(res.data.status)
            setdata({name:"",phone:"",email:"",subject:"",message:""})
            }
          })
          .catch(err => console.log(err))
    
    
    }


  return (
    <div>

    <Helmet>
    <title>Contact_us  - Be Right Vision</title>
    <meta name="description" content="Visit Us Anytime
Sheger city Melka Nono Sub city behind Gebriel Church" />
  </Helmet>
    <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4 fixed-bottom">
    <a href="tel:+251911351866" class="position-relative animated tada infinite">
        <i class="fa fa-phone-alt text-light fa-2x bg-danger rounded-circle p-3"></i>
        
        <span class="ring-wave"></span>
        <span class="ring-wave"></span>
    </a>
  </div>

    <div className="container-fluid bg-conbreadcrumb">
    <div className="bg-conbreadcrumb-single"></div>
    <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
      <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s"></h4>
      <ol className="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
        <li className="breadcrumb-item"><a href="index.html"></a></li>
        <li className="breadcrumb-item"><a href="#"></a></li>
        <li className="breadcrumb-item active text-primary"></li>
        <li className="breadcrumb-item"><a href="index.html"></a></li>
        <li className="breadcrumb-item"><a href="#"></a></li>
        <li className="breadcrumb-item active text-primary"></li>
      </ol>
    </div>
  </div>



    <div class="overflow-hidden py-6">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-md-6 col-xl-4" data-aos-delay="0" data-aos="fade-left" data-aos-duration="3000">
					<div class="d-flex mt-4 mb-3 p-3 p-lg-4 align-items-center bg-body-tertiary shadow rounded-4">
						<div class="p-4 text-white bg-primary rounded-circle">
							<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
								<path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
								<path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
							</svg>
						</div>

						<div class="ps-3">
							<h3 class="text-body-emphasis tracking-tight leading-7 text-xl fw-semibold">
								Visit Us Anytime
							</h3>
							<div class="text-body leading-6 text-sm ">
              Sheger city Melka Nono Sub city behind  Gebriel Church  
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-md-6 col-xl-4" data-aos-delay="100" data-aos="fade-left" data-aos-duration="3000">
					<div class="d-flex mt-4 mb-3 p-3 p-lg-4 align-items-center bg-body-tertiary shadow rounded-4">
						<div class="p-4 text-white bg-primary rounded-circle">
							<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
								<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
							</svg>
						</div>

						<div class="ps-3">
							<h3 class="text-body-emphasis tracking-tight leading-7 text-xl fw-semibold">
								Send An Email
							</h3>
							<div class="text-body leading-6 text-sm ">
							dejenefikadu@gmail.com
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-md-6 col-xl-4" data-aos-delay="200" data-aos="fade-left" data-aos-duration="3000">
					<div class="d-flex mt-4 mb-3 p-3 p-lg-4 align-items-center bg-body-tertiary shadow rounded-4">
						<div class="p-4 text-white bg-primary rounded-circle">
							<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
								<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
							</svg>
						</div>

						<div class="ps-3">
							<h3 class="text-body-emphasis tracking-tight leading-7 text-xl fw-semibold">
								Call Center
							</h3>
							<div class="text-body leading-6 text-sm ">
              0911351866
              <br/>
              0914855626
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    


  

    <div className="row gy-1 my-3 mx-2 shadow">

          <div className="col-lg-6">
         
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.3888124705863!2d38.64804917376104!3d9.02825059103304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164c791010570ef9%3A0xdfa4edd9e1e470a0!2zQXNoZXdhIE1lZGEgU3QgR2VicmllYWwgQ2h1cmNoIHwg4Yqg4Yi44YuL4Yic4YuzIOGJheGLseGItSDhjIjhiaXhiK3hiqThiI0g4Ymk4Ymw4Yqt4Yit4Yi14Ym14Yur4YqVIEFkZGlzIEFiYWJh!5e0!3m2!1sen!2set!4v1731102789169!5m2!1sen!2set" width="100%" height="400" style={{border:'0',borderRadius:'10px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="col-lg-6 contact ">
          <form onSubmit={handlecontact} className="php-email-form rounded">
          { resp? <div className="alert alert-success d-flex justify-content-center" role="alert">
                <strong>Your message has been sent. Thank you!</strong>
            </div>:<div><br/><br/><br/></div>}
          
              <div className="row ">
                <div className="col-lg-6 form-group m-0">
                  <input type="text" name="name" class="form-control rounded" id="name" placeHolder="Your Name"
                  value={data.name} onChange={e => setdata({ ...data, name: e.target.value })}
                   required/>
                </div>
                <div className="col-lg-6 form-group m-0">
                  <input type="email" class="form-control rounded" name="email" id="email" placeHolder="Your Email"
                  value={data.email} onChange={e => setdata({ ...data, email: e.target.value })}
                  required/>
                </div>
              </div>
              <div className="row ">
              <div className="col-lg-6 form-group m-0">
              <input type="number" maxLength={10} value={data.phone} onChange={e => setdata({ ...data, phone: e.target.value })} 
              class="form-control rounded" name="email" id="email" placeHolder="Your Phone number" required/>
            </div>
          
              <div className="form-group col-lg-6 m-0">
                <input type="text" class="form-control rounded" name="subject" id="subject" placeHolder="Subject"
                value={data.subject} onChange={e => setdata({ ...data, subject: e.target.value })}
                required/>
              </div>
              </div>
              <div className="form-group m-0">
                <textarea class="form-control rounded" name="message" rows="3" placeHolder="Message" required
                value={data.message} onChange={e => setdata({ ...data, message: e.target.value })}
                ></textarea>
              </div>
             
              <div class="text-center"> <button className="btn" type="submit" id="sendMessageButton">Send Message</button></div>
            </form>
          </div>

        </div>
    
    
    
    </div>
  )
}

export default Contact